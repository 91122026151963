import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.tcash,
    earningToken: serializedTokens.tcash,
    contractAddress: {
      77: '0x1542eE3c4A568a5f13E2AdC4B4929fFc2D14Ecec',
      100: '0x1542eE3c4A568a5f13E2AdC4B4929fFc2D14Ecec',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.1',
    sortOrder: 1,
    isFinished: false,
  },
]

export default pools
