import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   * modified by haruna.
   */
  {
    pid: 0,
    lpSymbol: 'TCASH',
    lpAddresses: {
      77: '0x1b976CdF6f35Da8090d9613A26E6562F79CD6717',
      100: '0x1b976CdF6f35Da8090d9613A26E6562F79CD6717',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wxdai,
  },
  {
    pid: 1,
    lpSymbol: 'TCASH-XDAI LP',
    lpAddresses: {
      77: '0x4443A76d93f2f1F85259e81639Bd1EA945f7f15C',
      100: '0x4443A76d93f2f1F85259e81639Bd1EA945f7f15C',
    },
    token: serializedTokens.tcash,
    quoteToken: serializedTokens.wxdai,
  },
  {
    pid: 2,
    lpSymbol: 'BUSD-XDAI LP',
    lpAddresses: {
      77: '0x8a4DaF5F371Cdfb2A05B65d6603C7de6e84a4bDf',
      100: '0x8a4DaF5F371Cdfb2A05B65d6603C7de6e84a4bDf',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wxdai,
  },
]

export default farms
