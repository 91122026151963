import { ChainId, Token } from '@etherflyer/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  syrup: new Token(
    MAINNET,
    '0x8264aa5B39BB14B49D4c1158009026f1401cD376',
    18,
    'SYRUP',
    'SyrupBar Token',
    '',
  ),
  wxdai: new Token(
    MAINNET,
    '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
    18,
    'WXDAI',
    'Wrapped XDAI',
    '',
  ),
  wbnb: new Token(
    MAINNET,
    '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
    18,
    'WXDAI',
    'Wrapped XDAI',
    '',
  ),
  // xdai here points to the wxdai contract. Wherever the currency XDAI is required, conditional checks for the symbol 'XDAI' can be used
  xdai: new Token(MAINNET, '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d', 18, 'XDAI', 'XDAI', ''),
  tcash: new Token(
    MAINNET,
    '0x1b976CdF6f35Da8090d9613A26E6562F79CD6717',
    18,
    'TCASH',
    'Etherflyer Token',
    '',
  ),
  dai: new Token(MAINNET, '0x44fA8E6f47987339850636F88629646662444217', 18, 'DAI', 'Dai Stablecoin from Ethereum', ''),
  usdc: new Token(MAINNET, '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83', 6, 'USDC', 'USDC from Ethereum', ''),
  busd: new Token(
    MAINNET,
    '0xdd96B45877d0E8361a4DDb732da741e97f3191Ff',
    18,
    'BUSD',
    'BUSD Token from BSC',
    '',
  ),
  usdt: new Token(MAINNET, '0x4ECaBa5870353805a9F068101A40E0f32ed605C6', 18, 'USDT', 'Tether on xDai', ''),
  eth: new Token(
    MAINNET,
    '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
    18,
    'ETH',
    'Wrapped Ether from Ethereum',
    ''
  ),
  safemoon: new Token(
    MAINNET,
    '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
    9,
    'SAFEMOON',
    'Safemoon Token',
    '',
  ),
  bondly: new Token(
    MAINNET,
    '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
    18,
    'BONDLY',
    'Bondly Token',
    '',
  )
}

export const testnetTokens = mainnetTokens

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
