import BigNumber from 'bignumber.js'
import { convertSharesToTcash } from 'views/Pools/helpers'
import { multicallv2 } from 'utils/multicall'
import tcashVaultAbi from 'config/abi/tcashVault.json'
import { getTcashVaultAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'

export const fetchPublicVaultData = async () => {
  try {
    const calls = [
      'getPricePerFullShare',
      'totalShares',
      'calculateHarvestTcashRewards',
      'calculateTotalPendingTcashRewards',
    ].map((method) => ({
      address: getTcashVaultAddress(),
      name: method,
    }))

    const [[sharePrice], [shares], [estimatedTcashBountyReward], [totalPendingTcashHarvest]] = await multicallv2(
      tcashVaultAbi,
      calls,
    )

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
    const totalTcashInVaultEstimate = convertSharesToTcash(totalSharesAsBigNumber, sharePriceAsBigNumber)
    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalTcashInVault: totalTcashInVaultEstimate.tcashAsBigNumber.toJSON(),
      estimatedTcashBountyReward: new BigNumber(estimatedTcashBountyReward.toString()).toJSON(),
      totalPendingTcashHarvest: new BigNumber(totalPendingTcashHarvest.toString()).toJSON(),
    }
  } catch (error) {
    return {
      totalShares: null,
      pricePerFullShare: null,
      totalTcashInVault: null,
      estimatedTcashBountyReward: null,
      totalPendingTcashHarvest: null,
    }
  }
}

export const fetchVaultFees = async () => {
  try {
    const calls = ['performanceFee', 'callFee', 'withdrawFee', 'withdrawFeePeriod'].map((method) => ({
      address: getTcashVaultAddress(),
      name: method,
    }))

    const [[performanceFee], [callFee], [withdrawalFee], [withdrawalFeePeriod]] = await multicallv2(tcashVaultAbi, calls)

    return {
      performanceFee: performanceFee.toNumber(),
      callFee: callFee.toNumber(),
      withdrawalFee: withdrawalFee.toNumber(),
      withdrawalFeePeriod: withdrawalFeePeriod.toNumber(),
    }
  } catch (error) {
    return {
      performanceFee: null,
      callFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    }
  }
}

export default fetchPublicVaultData
