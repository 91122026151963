import React from 'react'
import { Flex, Text } from '@etherflyer/uikit'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'contexts/Localization'
import { usePriceTcashBusd } from 'state/farms/hooks'
import { useTcashVault } from 'state/pools/hooks'
import { getTcashVaultEarnings } from 'views/Pools/helpers'
import RecentTcashProfitBalance from './RecentTcashProfitBalance'

const RecentTcashProfitCountdownRow = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const {
    pricePerFullShare,
    userData: { tcashAtLastUserAction, userShares, lastUserActionTime },
  } = useTcashVault()
  const tcashPriceBusd = usePriceTcashBusd()
  const { hasAutoEarnings, autoTcashToDisplay, autoUsdToDisplay } = getTcashVaultEarnings(
    account,
    tcashAtLastUserAction,
    userShares,
    pricePerFullShare,
    tcashPriceBusd.toNumber(),
  )

  const lastActionInMs = lastUserActionTime && parseInt(lastUserActionTime) * 1000
  const dateTimeLastAction = new Date(lastActionInMs)
  const dateStringToDisplay = dateTimeLastAction.toLocaleString()

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text fontSize="14px">{`${t('Recent TCASH profit')}:`}</Text>
      {hasAutoEarnings && (
        <RecentTcashProfitBalance
          tcashToDisplay={autoTcashToDisplay}
          dollarValueToDisplay={autoUsdToDisplay}
          dateStringToDisplay={dateStringToDisplay}
        />
      )}
    </Flex>
  )
}

export default RecentTcashProfitCountdownRow
