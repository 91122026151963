export default {
  masterChef: {
    100: '0x1542eE3c4A568a5f13E2AdC4B4929fFc2D14Ecec',
    77: '',
  },
  sousChef: {
    100: '0x38D06707daA3ae9c5F94663222231d7713117Dac',
    77: '',
  },
  multiCall: {
    100: '0xFAa296891cA6CECAF2D86eF5F7590316d0A17dA0',
    77: '',
  },
  etherflyerProfile: {
    100: '0xc4f05B3d7Fb2E8e8fc549a746c66311E27033140',
    77: '',
  },
  tcashVault: {
    100: '0x03c075c01BA696FBEb930aC2583b6065b9fc8C44',
    77: '',
  },
}
