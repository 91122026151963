import { Profile } from 'state/types'
import { getProfileContract } from 'utils/contractHelpers'

export interface GetProfileResponse {
  hasRegistered: boolean
  profile?: Profile
}

const profileContract = getProfileContract()
const profileApi = process.env.REACT_APP_API_PROFILE

export const getUsername = async (address: string): Promise<string> => {
  try {
    const response = await fetch(`${profileApi}/api/users/${address.toLowerCase()}`)

    if (!response.ok) {
      return ''
    }

    const { username = '' } = await response.json()

    return username
  } catch (error) {
    return ''
  }
}

/**
 * Intended to be used for getting a profile avatar
 */
export const getProfileAvatar = async (address: string) => {
  try {
    const hasRegistered = await profileContract.hasRegistered(address)

    if (!hasRegistered) {
      return null
    }
    return null
  } catch {
    return null
  }
}

export const getProfile = async (address: string): Promise<GetProfileResponse> => {
  try {
    const hasRegistered = await profileContract.hasRegistered(address)

    if (!hasRegistered) {
      return { hasRegistered, profile: null }
    }

    return { hasRegistered, profile:null }
  } catch (e) {
    console.error(e)
    return null
  }
}
